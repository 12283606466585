<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 style="margin-bottom: 0px;" class="fw-bold text-primary">Purchases</h4>
          <button class="btn-style pull-right" style="margin-top: -50px;float:right; display: none;" @click="$router.push('/purchases/add')">Generate PO</button>
          <!-- <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Purchase Orders this Month</p>
              <strong class="blue">{{purchasesMonth }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Purchase Orders Pending Approval</p>
              <strong class="blue">{{ purchasePending }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Purchase Orders Fulfilled</p>
              <strong class="blue">{{ purchasefulfilled }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Cancelled Purchase Orders</p>
              <strong class="red">{{ purchaseCancel }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Rejected Purchase Orders</p>
              <strong class="red">{{ purchaseReject }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p class="orange">Value of POs</p>
              <strong class="orange">Kshs {{ purchaseValue }}</strong>
            </div>
  
          </div> -->
  
          <div class="filter-search" style="margin-top: 0px; padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">PO No.</label>
                      <input type="text" class="form-control" v-model="poNo">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Status</label>
                      <select class="full" id="handled-by" v-model="status">
                        <option value="all">All</option>
                        <option value="pending">Pending</option>
                        <option value="active">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="fulfilled">Fulfilled</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Raised By</label>
                      <select class="full" id="handled-by" v-model="raisedById">
                        <option value="0">Any</option>
                        <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Approved By</label>
                      <select class="full" id="handled-by" v-model="approvedById">
                        <option value="0">Any</option>
                        <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!--
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Amount/Value</label>
                      <input type="number" class="form-control"  v-model="amount">
                    </div>
                  </div>
                  -->
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Project</label>
                      <input type="text" class="form-control" v-model="project">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Department</label>
                      <input type="text" class="form-control" v-model="departmentId">
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <button class="btn-style small" @click="search">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list"  style="margin-top: 0px;">
                <strong style="margin-top: 0px;">Purchase Table</strong>
                <!-- <keep-alive> -->
                <table id="purchasesTable" class="table table-hover">
                    <thead>
                        <th>No.</th>
                        <th>Date</th>
                        <th>PO No.</th>
                        <th>Department</th>
                        <th>Project</th>
                        <th>Raised By</th>
                        <th>Status</th>
                        <th>Exception</th>
                        <th>Fulfilled By</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </thead>
                </table>
                <LoaderItem v-if="loading"/>
              <!-- </keep-alive> 
                <div v-if="purchases.length < 1">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No purchase found</label>
                </div>-->
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { docFooter } from '../../utils/common'
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import LoaderItem from '../../components/LoaderItem'
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  export default {
    name: 'HomePurchases',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        purchasesMonth:100,
        purchasePending: 30,
        purchasefulfilled: "34/89",
        purchaseCancel:10,
        purchaseReject: 10,
        purchaseValue: "1,000,000",
        purchases: [],
        dateFrom: '', 
        dateTo: '',
        status: "", 
        raisedById: 0, 
        approvedById: 0, 
        dataTable:  null,
        processedData: [],
        loading: false, 
        amount: 0.0, 
        project: "", 
        departmentId: "",
        poNo: "",
        purchaseDatas: []

    }),
    mounted(){
        this.fetchUsers()
        this.getPurchases()
        window.deleteAlert = this.deleteAlert;
    },

    computed:{
      ... mapGetters (["getUsers"]),
      users () { return this.getUsers },
    },
    methods:{
      ... mapActions(["fetchUsers"]),
      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          let purchaseNo =  "";
          let exception =  "";
          let fulfilledName =  "";

          if(item.batchNo){
            purchaseNo = `${item.purchaseNo} <span style="font-size: 16px;"> - ${item.batchNo}</span>`
          } else {
            purchaseNo = item.purchaseNo
          }

          if(item.exception == 0){
            exception = '<button class="btn btn-primary btn-sm" style="margin-left: 5px; border-radius: 10px">OK</button>'
          } else {
            exception = '<button class="btn btn-danger btn-sm" style="margin-left: 5px; border-radius: 10px">!</button>'
          }
          

          if(item.fulfilledName){
            fulfilledName = item.fulfilledName
          } else {
            fulfilledName = ' - '
          }
          
          if(item.status == 'completed'){
            status = '<button class="btn btn-success btn-sm" >Completed</button>'
          } else if(item.status == 'fulfilled'){
            status = '<button class="btn btn-primary btn-sm" >Fulfilled</button>'
          } else if(item.status == 'cancelled'){
            status = '<button class="btn btn-danger btn-sm" >Cancelled</button>'
          } else if(item.status == 'rejected'){
            status = '<button class="btn btn-danger btn-sm" >Rejected</button>'
          } else if(item.status == 'approved'){
            status = '<button class="btn btn-success btn-sm"  style="margin-left: 5px; border-radius: 15px">Approved</button>'
          } else if(item.status == 'pending'){
            status = '<button class="btn btn-warning btn-sm" style="border-radius: 15px" >Pending</button>'
          } else {
            status = `<button class="btn btn-default btn-sm" style="text-transform: capitalize; border: 1px solid #4448B3; border-radius: 15px" >${item.status}</button>`
          }

          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            purchaseNo,
            department: item.department,
            project: item.project,
            raisedName: item.name,
            status,
            exception,
            fulfilledName,
            amount: this.formatMoney(item.amount),
            action:'',
            itemId: item.id,
            statusRow: item.status
          };
        });
      },

      reinitializeTable(){
        this.dataTable.clear()
        this.dataTable.rows.add(this.preprocessData(this.purchaseDatas))
        this.dataTable.draw()
      },


    clear(){
        this.status = ""
        this.raisedById = 0
        this.approvedById = 0
        this.amount = 0.0
        this.projectId = 0
        this.departmentId = ""
        this.poNo= ""
        this.reinitializeTable()
      }, 

      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#purchasesTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            destroy: true,
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdAt' },
              { data: 'purchaseNo' }, 
              { data: 'department' }, 
              { data: 'project' }, 
              { data: 'raisedName' }, 
              { data: 'status' }, 
              { data: 'exception' },
              { data: 'fulfilledName' }, 
              { data: 'amount' }, 
              { data: 'action', render:this.renderActionButton }
            ],
            columnDefs: [
              {
                targets: 0,  searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },

      renderActionButton(data, type, row) {
        if (type === 'display') {
          let action ='';
          action +=`<button class="btn btn-sm btn-danger" style="margin-right: 10px;" onClick="deleteAlert(${row.itemId})">Delete</button>`;
          //action +=`<a class="btn btn-sm btn-default" style="border: 1px solid black;" href="/purchases/pdf/${row.itemId}">View</a>`;
          return action;
        }
        return data;
      },
     

      async search(){
        console.log("Raised by Id",this.raisedById)
        console.log("Approved by Id", this.approvedById)
        console.log(this.approvedById)
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                poNo: this.poNo,
                status: this.status, 
                raisedById: this.raisedById, 
                approvedById: this.approvedById,
                amount: this.amount,
                project: this.project,
                departmentId: this.departmentId,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/purchases/search', requestOptions)
        const data = await res.json()
        console.log("Response", data)
        if(data.status == true){
            this.purchases = data.purchases
            this.dataTable.clear();
            this.dataTable.rows.add(this.preprocessData(this.purchases));
            this.dataTable.draw();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: data.message
            })
        }
      },

      customizePDF(doc) {
        const profile = JSON.parse(localStorage.getItem('profile'))
        doc.pageMargins = [40, 40, 40, 40]; 
        doc.defaultStyle.columnGap = 0
        doc.content[1].fillColor = '#f2f2f2'

        doc.header = () => {
          return {
            columns: [
              {
                width: '*',
                columns: [
                  /* {
                      image: logoBase64,
                      width: 150, // Adjust the width as needed
                  },*/
                  {
                    text: profile?.businessName,
                    alignment: 'left',
                    margin: [40, 15, 0, 40], // Adjust margins as needed
                    color: '#FFA500', // Orange color
                    bold: true,
                    fontSize: 14,

                  }
                ]
              },
              {
                width: '*',
                stack: [
                  {
                    text: 'Purchases',
                    alignment: 'right',
                    margin: [0, 15, 50, 0],
                    fontSize: 12,
                    color: '#4448B3'
                  },
                  {  
                    alignment: 'right',
                    margin: [300, 0, 0, 0],
                    width: '*',
                    columns: [
                      {
                        width: 'auto',
                        text: 'Date:',
                        alignment: 'right',
                        margin: [0, 0, 10, 0],
                        bold: true,
                      },
                      {
                        width: 'auto',
                        text: `${this.formatDate(new Date(), "true")}`,
                        alignment: 'right',
                        margin: [0, 0, 50, 0],
                        fontSize: 9
                      }
                    ]
                  }
                ]
              }
            ],
            fillColor: '#000000'
          };
        },

        doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

        doc.styles.title = {
          color: '#4448B3',
          fontSize: 13,
          margin: [80, 0, 0, 40],
        };
      },


      async getPurchases () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          
          try{
            this.loading = true
            const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
            const data = await res.json()
            this.purchases = data.purchases
            console.log(this.purchases)
            this.purchaseDatas = data.purchases
            this.processedData = this.preprocessData(data.purchases);
            //if(typeof this.dataTable == 'null'){
            this.dataTable = null;
            this.initializeDataTable();
            // } else {
            //   this.dataTable.destroy()
            //   this.dataTable = null;
            //   this.initializeDataTable();
            // }
          }catch(err){
              console.log(err)
          }finally{
              this.loading = false
          }
      },
      deleteAlert(id){
          Swal.fire({
            title: 'Confirm Purchase Order Deletion',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.deletePurchase(id);
            }
          })
      },
      async deletePurchase(id) {
        const requestOptions = {
              method: "DELETE",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token }
          };
          const res = await fetch(process.env.VUE_APP_URL+'/purchases/'+id, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Purchase Order Deleted',
                  text: 'Saved Purchase Order deleted successfully'
              })
            this.getPurchases()
            this.clear()
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Purchase Order not deleted',
                  text: data.message
              })
          }
      }
    },
    beforeRouteLeave() {
      if (this.dataTable && typeof this.dataTable.destroy === 'function') {
        this.dataTable.destroy();
        this.dataTable = null
      }
    }

  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>