<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 class="fw-bold text-primary">Suppliers</h4>
          <button class="btn-style small pull-right" style="margin-top: -50px;float:right;display: none;" @click="$router.push('/suppliers/add')">Add Supplier</button>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>No. of Suppliers</p>
              <strong class="blue">{{ suppliers.length }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search"  style="margin-top: -40px; padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Supplier Name</label>
                      <input type="text" class="form-control" v-model="supplierName" @input="filterTable('name')">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Supplier Code</label>
                      <input type="text" class="form-control" v-model="supplierCode" @input="filterTable('code')">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Nature of Business</label>
                      <input type="text" class="form-control"  v-model="businessNature" @input="filterTable('business')">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Qualification Status</label>
                      <select class="full" id="handled-by" v-model="qualification" @input="filterTable('qualification')">
                        <option value="All">All</option>
                        <option value="Pre-qulaified">Pre-qualified</option>
                        <option value="Not Pre-qualified">Not Pre-qualified</option>
                      </select>
                    </div>
                  </div>

                  <!--
                    <div class="col-md-1 align-self-end">
                      <div class="form-group">
                        <a href="#" class="btn-style small">Filter</a>
                      </div>
                    </div>
                  -->
                  
                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list" style="margin-top: 0px">
                <strong style="margin-top: 0px;">Suppliers List</strong>
                <keep-alive>
                <table id="suppliersTable" class="table table-hover">
                    <thead>
                        <th>No.</th>
                        <th>Supplier Name & Code</th>
                        <th>Nature of Business</th>
                        <th>Status</th>
                        <th>No. of POs</th>
                        <th>PO Amount to Date</th>
                        <th>Action</th>
                    </thead>
                </table>
              </keep-alive>
              <LoaderItem v-if="loading"/>
              <!--
                <div v-if="suppliers.length < 1">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No supplier found</label>
                </div>
              -->
                
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
import Header from '../../components/Header';
import LoaderItem from '../../components/LoaderItem'
import 'datatables.net-vue3';
import 'datatables.net-bs5';
import "datatables.net-buttons-bs5";
import "datatables.net-responsive-bs5";
import $ from 'jquery';
import 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.print.js';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { docFooter } from '@/utils/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
    name: 'SuppliersPage',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        suppliers: [],
        processedData: [],
        loading: false,
        supplierName: "", 
        supplierCode: "", 
        businessNature: "",
        qualification: "",
        dataTable:  null,

    }),
    mounted(){
        this.getSuppliers()
    },
    methods:{
      getTotal(arrayL){
        //console.log( {arrayL})
        if(arrayL){
          let total =0;
          arrayL.forEach(element => {
            element.PurchaseProduct.forEach( smallEl => {
              total = total+ smallEl.price
            })
          });
          return total;
        } else{
          return 0;
        }
      },

      clear(){
        this.supplierName = ""
        this.supplierCode = "", 
        this.businessNature = "",
        this.qualification = ""
      },

      
      async getSuppliers () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
          try{
            this.loading = true
            const res = await fetch(process.env.VUE_APP_URL+'/suppliers', requestOptions)
            this.suppliers = await res.json()
            this.processedData = this.preprocessData(this.suppliers);
            this.initializeDataTable();
            }catch(err){
              //console.log(err)
          }finally{
              this.loading = false
          }
          
        },


      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          let amount =  "";
          
          if(item.prequalified){
            status = '<button class="btn btn-primary btn-sm" style="border-radius: 15px" >Pre-qualified</button>'
          } else {
            status = '<button class="btn btn-danger btn-sm" style="border-radius: 15px" >Not Pre-qualified</button>'
          }

          if(item.purchases?.length > 0){
            amount = this.formatMoney(this.getTotal(item.purchases))
          } else {
            amount = '0.00'
          }

          return {
            indexNo:"1",
            name: `${item.name} - ${item.code}`,
            business: item.business,
            status,
            noPos: item.purchases?.length,
            amount,
            action: `<a class="btn btn-sm btn-default" style="border: 1px solid black;" href="/suppliers/${item.id}">View Details</a>`
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#suppliersTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'name' },
              { data: 'business' }, 
              { data: 'status' }, 
              { data: 'noPos' }, 
              { data: 'amount' }, 
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          //console.log({error})
        }
      },

      filterTable(arg) {
        switch (arg) {
          case 'name':
            this.dataTable.search(this.supplierName).draw()
          break
          default:
            return
        }
      },
      
      customizePDF(doc) {
      //const logoBase64 = this.convertToBase64(logo)
      //console.log(logoBase64)
      const profile = JSON.parse(localStorage.getItem('profile'))
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#FFA500', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Suppliers',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },

      doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    },
    }
  }
  </script>

  <style scoped>
  .suppliers-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>