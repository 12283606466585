<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4  class="fw-bold text-primary" >Stock Transfer Logs</h4>
  
          <div class="filter-search" style="margin-top: 0px; padding-left:0px">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear"  @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" @change="filterTable"/>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" @change="filterTable" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="products-list transaction-products-list" style="margin-top: 0px;">
            <strong style="margin-top: 0px">Stock Transfer Log List</strong>
            <!-- <keep-alive> -->
            <table class="table table-hover" id="stockTransferTable">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Product Description</th>
                <th>Qty Moved</th>
                <th>Old Warehouse</th>
                <th>New Warehouse</th>
                <th>Transfer Reason</th>
                <th>Request by</th>
                <th>Comment</th>
                <th>Status</th>
              </thead>
            </table>

            <LoaderItem v-if="loading"/>


          <!-- </keep-alive> 
            <div v-if="activities.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No log found</label>
            </div>-->

          </div>

        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  import LoaderItem from '../../components/LoaderItem'
  import Header from '../../components/Header';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
import { docFooter } from '@/utils/common';
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
    name: 'StockTransfers',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        dateFrom: "",
        dateTo: "",
        activities: [],
        dataTable:  null,
        processedData: [], 
        loading: false,
        activitiesDatas: []
    }),
    mounted(){
        this.getActivities()
    },
    methods:{
      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          
          if(item.status == 'pending'){
            status = '<button class="btn btn-warning btn-sm" >Pending</button>'
          }
          if(item.status == 'done'){
            status = '<button class="btn btn-success btn-sm" style="">Completed</button>'
          }

          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            productName: item.Product.name,
            quantity: this.formatQty(item.quantity),
            oldWarehouse: item.Inventory.Warehouse.name,
            newWarehouse: item.Warehouse?.name,
            reason: item.reason,
            comment: item.comment,
            userName: item.User?.name,
            status
          };
        });
      },

      reinitializeTable(){
          this.dataTable.clear()
          this.dataTable.rows.add(this.preprocessData(this.activitiesDatas))
          this.dataTable.draw()
      },

      clear(){
        this.dateFrom = ""
        this.dateTo = ""
        this.reinitializeTable()
      },

      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#stockTransferTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdAt' },
              { data: 'productName' }, 
              { data: 'quantity' }, 
              { data: 'oldWarehouse' }, 
              { data: 'newWarehouse' }, 
              { data: 'reason' }, 
              { data: 'comment' },
              { data: 'userName' }, 
              { data: 'status' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          //console.log({error})
        }
      },

      convertDate(dateString) {
        const parts = dateString.split("/")
        const newDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
        return newDate;
      },


      filterTable() {
        if (!this.dateFrom || !this.dateTo) {
          console.error("Invalid date inputs");
          return;
        }

        const fromDate = new Date(this.dateFrom);
        const toDate = new Date(this.dateTo);

        if (isNaN(fromDate) || isNaN(toDate)) {
          console.error("Invalid date values");
          return;
        }

        $.fn.dataTable.ext.search.push((settings, data, dataIndex) => {
          const dateStr = data[1]; 
          const parsedDate = this.convertDate(dateStr);
          const date = new Date(parsedDate);
         // console.log(settings)
          console.log(dataIndex)
          if (!isNaN(date)) {
           // console.log("From Date", fromDate)
           // console.log("Date", date)
           // console.log("To Date", toDate)

            return date >= fromDate && date <= toDate;
          }
          return false;
        });
        this.dataTable.draw();
        $.fn.dataTable.ext.search.pop();
      },

      customizePDF(doc) {
      //const logoBase64 = this.convertToBase64(logo)
      //console.log(logoBase64)
      const profile = JSON.parse(localStorage.getItem('profile'))
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#FFA500', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Stock Transfers',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },

      doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    },

    async getActivities () {
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };

        try{
          this.loading = true
          const res = await fetch(process.env.VUE_APP_URL+'/stocktransfers', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.activities = data.stocks
              this.activitiesDatas = this.activities
              this.processedData = this.preprocessData(data.stocks);
              this.initializeDataTable();
          }
        }catch(err){
           // console.log(err)
        }finally{
            this.loading = false
        }
      },
    }
  }

</script>
